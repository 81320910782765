<template>
  <div class="welcome_page custom_scrollbar">

    <div class="contacts">
      <!-- <div class="contacts_item">
        <div class="icon callIcon"></div>
        <div class="text">+ 7 (800) 555-55-55</div>
      </div>
      <div class="contacts_item">
        <div class="icon emeilIcon"></div>
        <div class="text">info@bingosoft.ru</div>
      </div> -->
      <div class="contacts_item">
        <div class="icon emeilIcon"></div>
        <a href="https://support.russianpost.ru/portal/navigator-service-call.html?service=service$2421508201" target="_blank">
          <div class="doc_text" style="font-size: 18px">Обратиться в Службу поддержки АИС УИ</div>
        </a>
      </div>
      <div class="contacts_item" style="margin-left: auto; margin-right: 50px;">
        <!-- <div class="icon emeilIcon"></div> -->
        <a href="" @click.prevent="openDashboard">
          <div class="doc_text" style="font-size: 18px">История релизов</div>
        </a>
      </div>
    </div>

    <div class="main">

      <div class="title">
        <div class="title_main">Добро пожаловать</div>
        <div class="text28">Выберите пункт в меню слева, чтобы начать работу</div>
      </div>

      <div class="document">
        <div class="docs_column">
          <div class="doc_item">
            <a href="/homepage/Pochta/Инструкция по заполнению формы в АИС УИ.pdf" download>
              <div class="doc_icon"></div>
              <div class="doc_text">Инструкция по заполнению формы в АИС УИ</div>
            </a>
          </div>
          <div class="doc_item" style="display: none;">
            <a href="/homepage/Pochta/Формирование инвестзаявок.docx" download>
              <div class="doc_icon"></div>
              <div class="doc_text">Инструкция по формированию заявок</div>
            </a>
          </div>
        </div>
        <div class="docs_column" style="display: none;">
          <div class="doc_item">
            <a href="/homepage/Pochta/Руководство по формированию корректировки проекта.docx" download>
              <div class="doc_icon"></div>
              <div class="doc_text">Руководство по формированию корректировки проекта</div>
            </a>
          </div>
          <div class="doc_item" style="display: none;">
            <a href="/homepage/empty_file.docx" download>
              <div class="doc_icon"></div>
              <div class="doc_text">Руководство по формированию отчетности</div>
            </a>
          </div>
        </div>
      </div>

    </div>

    <div class="footer">
      <div class="logo_frame"></div>
      <div class="footer__name">
        <div class="footer_text text28">Автоматизированная Информационная Система Управления Инвестициями</div>
        <div class="footer_images">
          <div class="image_1"></div>
          <div class="stick"></div>
          <div class="image_2"></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'WelcomePage',
  inject: {
    addMainTab: {
      default: () => {}
    }
  },
  mounted () {
    this.$el.classList.add('background_' + (1 + Math.floor(Math.random() * Math.floor(5))))
  },
  methods: {
    openDashboard () {
      this.addMainTab({ name: 'История релизов', componentType: 'Dashboard', payload: { id: 82, value: 'Dashboard' } })
    }
  }
}
</script>

<style scoped src="./Welcome.css">
</style>
